<script lang="ts" setup>
import type { ValueProposition } from '~/composables/useValueProposition'

const {
  values,
  variant = 'default',
  hideText = false,
} = defineProps<{
  values: ValueProposition[]
  variant?: 'default' | 'card'
  hideText?: boolean
}>()

const ui = computed(() => {
  switch (variant) {
    case 'card':
      return {
        container: 'grid grid-cols-1 gap-3 md:grid-cols-4',
        item: {
          base: 'flex items-center rounded-lg border bg-white p-6 text-center',
          image: 'mr-3 basis-1/3',
          text: 'basis-2/3 text-left',
        },
      }
    default:
      return {
        container: 'grid grid-cols-1 gap-6 md:grid-cols-2',
        item: {
          base: 'text-center',
          image: '',
          text: 'mt-2',
        },
      }
  }
})
</script>

<template>
  <div :class="ui?.container">
    <div
      v-for="{ id, image, title, text } in values"
      :key="id"
      :class="ui.item.base"
    >
      <figure :class="ui.item.image">
        <NuxtPicture
          :src="image"
          :alt="title"
          :img-attrs="{ class: 'mx-auto block h-16 object-contain' }"
          sizes="80px"
        />
      </figure>
      <div :class="ui.item.text">
        <p class="font-semibold text-primary-500">{{ title }}</p>
        <p v-if="!hideText" v-html="text" />
      </div>
    </div>
  </div>
</template>
